import React from 'react'
import { graphql } from 'gatsby'
import { Contact } from '@templates'
import { prismicOfficeDetailsFields, prismicMailingListFields } from '@graphql/fragments'

const ContactPage = ({ data }) => <Contact data={data} />
ContactPage.fragments = [prismicOfficeDetailsFields]

export default ContactPage

export const query = graphql`
  query PrismicContactPage {
    prismic {
      allContact_pages {
        edges {
          node {
            office_one {
              ...prismicOfficeDetailsFields
            }
            recruitment_title
            recruitment_message
            seodescription
            seotitle
            mailinglist {
              ... on PRISMIC_Mailing_list {
                ...prismicMailingListFields
              }
            }
          }
        }
      }
    }
  }
`

ContactPage.query = query
